import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from './../../forms/Button';
import { useDispatch } from 'react-redux';
import { addProduct } from './../../../redux/Cart/cart.actions';

const Product = (product) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    documentID,
    productThumbnail,
    productName,
    productPrice
  } = product;
  if (!documentID || !productThumbnail || !productName ||
    typeof productPrice === 'undefined') return null;

  const configAddToCartBtn = {
    type: 'button'
  };

  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(
      addProduct(product)
    );
    history.push('/cart');
  };

  const handleCheckAvailability = (product) => {
    if (!product) return;
    history.push(`/availability/${documentID}`);
  };

  if (productName === 'Video - Consulenza Commercialista' ||
    productName === 'Video - Consulenza Legale' ||
    productName === 'Video - Consulenza Ing. Informatico'
  ) 
  {
    return (
      <div className="product">
        <div className="thumb">
          <Link to={`/availability/${documentID}`}>
            <img src={productThumbnail} alt={productName} />
          </Link>
        </div>
  
        <div className="details">
          <ul>
            <li>
              <span className="name">
                <Link to={`/availability/${documentID}`}>
                  {productName}
                </Link>
              </span>
            </li>
            <li>
              <span className="price">
                €{productPrice}
              </span>
            </li>
            <li>
              <div className="addToCart">
                <Button {...configAddToCartBtn} onClick={() => handleCheckAvailability(product)}>
                  Prenota
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  if (productName === 'Upload documenti') 
  {
    return (
      <div className="product">
        <div className="thumb">
          <Link to={`/product/${documentID}`}>
            <img src={productThumbnail} alt={productName} />
          </Link>
        </div>
  
        <div className="details">
          <ul>
            <li>
              <span className="name">
                <Link to={`/product/${documentID}`}>
                  {productName}
                </Link>
              </span>
            </li>
            <li>
              <span className="price">
                €{productPrice}
              </span>
            </li>
            <li>
              <div className="addToCart">
                <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
                  Aggiungi al carrello
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  else {
    return (
      <div className="product">
        <div className="thumb">
          <Link to={`/product/${documentID}`}>
            <img src={productThumbnail} alt={productName} />
          </Link>
        </div>
  
        <div className="details">
          <ul>
            <li>
              <span className="name">
                <Link to={`/product/${documentID}`}>
                  {productName}
                </Link>
              </span>
            </li>
            <li>
              <span className="price">
                €{productPrice}
              </span>
            </li>
            <li>
              <div className="addToCart">
                <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
                  Aggiungi al carrello
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  
};

export default Product;