import React from 'react';
import MainPage from './../../components/MainPage';

import './styles.scss';

const Homepage = props => {
  return (
    <section>
      <div>
        <MainPage/>
      </div>
    </section>
  );
};

export default Homepage;