import React, { useEffect } from 'react';
import {
  TableContainer, Table, TableHead,
  TableBody, TableRow, TableCell
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setOrderDetails } from './../../redux/Orders/orders.actions';
import UploadDoc from '../../components/UploadDoc';


const columns = [
  {
    id: 'productThumbnail',
    label: ''
  },
  {
    id: 'productName',
    label: 'Nome'
  },
  {
    id: 'productPrice',
    label: 'Prezzo'
  },
  {
    id: 'quantity',
    label: 'Quantità'
  }
]

const styles = {
  fontSize: '16px',
  width: '10%'
};

const formatText = (columnName, columnValue) => {
  switch(columnName) {
    case 'productPrice':
      return `€${columnValue}`;
    case 'productThumbnail':
      return <img src={columnValue} width={250} />;
    default:
      return columnValue;
  }
}

const OrderDetails = ({ order }) => {
  const dispatch = useDispatch();
  const orderItems = order && order.orderItems;

  useEffect(() => {
    return () => {
      dispatch(
        setOrderDetails({})
      );
    }
  }, []);

  return (
    <TableContainer>
      <Table>

        <TableHead>
          <TableRow>

            {columns.map((col, pos) => {
              return (
                <TableCell
                  key={pos}
                  style={styles}
                >
                  {col.label}
                </TableCell>
              )
            })}

          </TableRow>
        </TableHead>

        <TableBody>

          {(Array.isArray(orderItems) && orderItems.length > 0) && orderItems.map((row, pos) => {
            try {
              if (row.upload == 'in corso... aggiungi elemento -->') {
                // console.log(row)
                const upState = row.upload
                return (
                  <TableRow key={pos}>
                    {columns.map((col, pos) => {
                      const columnName = col.id;
                      const columnValue = row[columnName];
                      if (columnName === 'productName') {
                        return (
                          <TableCell
                            key={pos}
                            style={styles}
                          >
                            {formatText(columnName, columnValue + '. Stato: ' + upState)}
                            <span style = {{ width: 'fit-content', display: 'inline-flex' }}><UploadDoc row = {row}/></span>
                          </TableCell>
                        )
                      }
                      else {
                        // console.log('seconda',columnName)
                        return (
                          <TableCell
                            key={pos}
                            style={styles}
                          >
                            {formatText(columnName, columnValue)}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                )
              }

              if (row.upload == 'Elemento aggiunto.') {
                const upState = row.upload
                return (
                  <TableRow key={pos}>
                    {columns.map((col, pos) => {
                      const columnName = col.id;
                      const columnValue = row[columnName];
                      if (columnName === 'productName') {
                        return (
                          <TableCell
                            key={pos}
                            style={styles}
                          >
                            {formatText(columnName, columnValue + '. Stato: ' + upState)}
                          </TableCell>
                        )
                      }
                      else {
                        // console.log('seconda',columnName)
                        return (
                          <TableCell
                            key={pos}
                            style={styles}
                          >
                            {formatText(columnName, columnValue)}
                          </TableCell>
                        )
                      }
                    })}
                  </TableRow>
                )
              }

              if (row.reservation.date) {
                const dateRev = row.reservation.date
                return (
                  <TableRow key={pos}>
                   {/* {row.reservation.date} */}
                    {columns.map((col, pos) => {
                      const columnName = col.id;
                      const columnValue = row[columnName];
                      if (columnName === 'productName') {
                        // console.log('prima',columnName)
                        return (
                          <TableCell
                            key={pos}
                            style={styles}
                          >
                            {formatText(columnName, columnValue + '. Fissato in data, ore: ' + dateRev + ' con durata di 30 minuti.')}
                          </TableCell>
                        )
                      }
                      else {
                        // console.log('seconda',columnName)
                        return (
                          <TableCell
                            key={pos}
                            style={styles}
                          >
                            {formatText(columnName, columnValue)}
                          </TableCell>
                        )
                      }
                      
                    })}
                  </TableRow>
                )
              }
            }
            
            catch {
              return (
                <TableRow key={pos}>
                  {columns.map((col, pos) => {
                    const columnName = col.id;
                    const columnValue = row[columnName];
                    return (
                      <TableCell
                        key={pos}
                        style={styles}
                      >
                        {formatText(columnName, columnValue)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            }
          })}

        </TableBody>

      </Table>
    </TableContainer>
  )
}

export default OrderDetails;
