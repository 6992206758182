import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './../../assets/logo.png';

import Servizi from './../../assets/servizi.jpg';
import SM from './../../assets/santamaria.jpg';
import Shop from './../../assets/shop.jpg';
import Crowd from './../../assets/crowdfunding.jpg';
import Studio from './../../assets/studio.jpg';
import BTD from './../../assets/btd.png';
import VDI4 from './../../assets/loghiVDI4.jpg';

import Figure from 'react-bootstrap/Figure';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';


import './styles.scss';

const Homepage = props => {
  return (
    <Jumbotron className="mainContainer" fluid>
      <Container fluid="md">
        <Row className="justify-content-md-center" style={{textAlignLast: "center"}}>
          <Col md="12">
          <Figure>
            <Figure.Image
              src={Logo}
            />
          </Figure>
          
          </Col>
          <Col md="12">
            <Card.Text>
              Storico studio di consulenza Amministrativa Fiscale e Tributaria.
            </Card.Text>
          </Col>
          
        </Row>
        <Row className="justify-content-md-center">
          <Row>
            <Col md="auto">
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={Studio} />
                  <Card.Body>
                    <Card.Title>Chi siamo</Card.Title>
                    <Card.Text>
                      Storico studio di consulenza Amministrativa Fiscale e Tributaria.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                <a href='/contatti'><Card.Img variant="top" src={SM} /></a>
                  <Card.Body>
                    <Card.Title>Dove siamo</Card.Title>
                    <Card.Text>
                      Siamo in Via Los Angeles, 65, 06081 Santa Maria degli Angeli (PG).{' '}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="text-center">
                <a href='/service'><Card.Img variant="top" src={Servizi} /></a>
                  <Card.Body>
                    <Card.Title>I nostri servizi</Card.Title>
                    <Card.Text>
                      Offriamo servizi in ambito commerciale, legale ed informatico.{' '}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                <a target='_blank' href='/bridgetodigital'><Card.Img variant="top" src={BTD} /></a>
                  <Card.Body>
                    <Card.Title>Bridge To Digital 2020</Card.Title>
                    <Card.Text>
                      Interventi straordinari per la digitalizzazione delle imprese.{' '}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                <a target='_blank' href='/vdimpresa'><Card.Img variant="top" src={VDI4} /></a>
                  <Card.Body>
                    <Card.Title>Voucher Digitali I4.0</Card.Title>
                    <Card.Text>
                      Iniziativa del Punto Impresa Digitale (PID).{' '}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>

          <Row>
            <Col>
              <CardDeck>
                <Card>
                <a href='/progetti'><Card.Img variant="top" src={Crowd} /></a>
                  <Card.Body>
                    <Card.Title>Crowdfunding</Card.Title>
                    <Card.Text>
                      Area per finanziare i progetti dello studio.
                    </Card.Text>
                    {/* <Card.Text>
                      <small className="text-muted">Last updated 3 mins ago</small>
                    </Card.Text> */}
                  </Card.Body>
                </Card>
                <Card>
                <a href='/service'><Card.Img variant="top" src={Shop} /></a>
                  <Card.Body>
                    <Card.Title>Shop online</Card.Title>
                    <Card.Text>
                      Richiedi preventivi ed acquista i nostri servizi e le nostre video - consulenze online.{' '}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Row>

      </Container>
    </Jumbotron>
  );
};

export default Homepage;
