import React from 'react';
import CrowfoundingComponent from '../../components/Crowdfunding';

import './styles.scss';

const Crowdfunding  = props => {
  return (
    <section>
      <div>
        <CrowfoundingComponent/>
      </div>
    </section>
  );
};

export default Crowdfunding;