import React from 'react';
import { useDispatch } from 'react-redux';
import { removeCartItem, addProduct, reduceCartItem } from '../../../redux/Cart/cart.actions';

const Item = (product) => {
  const dispatch = useDispatch();
  const {
    productName,
    productThumbnail,
    productPrice,
    quantity,
    documentID,
    reservation,
  } = product;

  const handleRemoveCartItem = (documentID) => {
    dispatch(
      removeCartItem({
        documentID
      })
    );
  }

  const handleAddProduct = (product) => {
    dispatch(
      addProduct(product)
    )
  }

  const handleReduceItem = (product) => {
    dispatch(
      reduceCartItem(product)
    );
  }

  return (
    <table className="cartItem" border="0" cellSpacing="0" cellPadding="10">
      <tbody>
        <tr>
          <td>
            <img src={productThumbnail} alt={productName} />
          </td>
          <td>
            <p>{productName}</p> 
            <p>in data: </p>
            <p>{reservation.date}</p>
          </td>
          {/* <td>
            
            <span>
              {'30 min'}{' x '}{quantity}{' '}
            </span>
            
          </td> */}


          <td>
            <span className="cartBtn"
              onClick={() => handleReduceItem(product)}>
              {<i class="fas fa-minus"></i>}
            </span>
            <span>
              {' '}{30*quantity}{' min '}
            </span>
            <span className="cartBtn"
              onClick={() => handleAddProduct(product)}>
              {<i class="fas fa-plus"></i>}
            </span>
          </td>


          <td>
            €{productPrice}
          </td>
          <td align="center">
            <span className="cartBtn remove" onClick={() => handleRemoveCartItem(documentID)}>
              <i class="fas fa-times"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Item;
