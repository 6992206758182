import React from 'react';
import ContattiComponent from '../../components/Contatti';

import './styles.scss';

const Contatti  = props => {
  return (
    <section>
      <div>
        <ContattiComponent/>
      </div>
    </section>
  );
};

export default Contatti;