import React from 'react';
import { Link } from 'react-router-dom';
import ShopCommercialista from './../../assets/commercialista.jpg';
import ShopInformatico from './../../assets/informatico.jpg';
import ShopLegale from './../../assets/legale.png';
import './styles.scss';

const Directory = props => {
  return (
    <div className="directory">
      <div className="wrap">
        <div
          className="item"
          style={{
            backgroundImage: `url(${ShopCommercialista})`
          }}
        >
          <Link to="/search/commercialista">
            Il commercialista online
          </Link>
        </div>
        
        <div
          className="item"
          style={{
            backgroundImage: `url(${ShopLegale})`
          }}
        >
          <Link to="/search/legale">
            Servizi legali
          </Link>
        </div>

        <div
          className="item"
          style={{
            backgroundImage: `url(${ShopInformatico})`
          }}
        >
          <Link to="/search/informatico">
            Servizi informatici
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Directory;
