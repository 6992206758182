import { firestore, arrayUnion } from './../../firebase/utils';
// export const handleSaveOrder = order => {
//   return new Promise((resolve, reject) => {
//     firestore
//       .collection('orders')
//       .doc()
//       .set(order)
//       .then(() => {
//         resolve();
//       })
//       .catch(err => {
//         reject(err);
//       });
//   });
// };

export const handleSaveOrder = order => {
  try {
    var i;
    for (i = 0; i < 20; i++)  {
      if (
        order.orderItems[i].productName == 'Video - Consulenza Commercialista' ||
        order.orderItems[i].productName == 'Video - Consulenza Legale' ||
        order.orderItems[i].productName == 'Video - Consulenza Ing. Informatico'
        ) {
        
        // console.log('if', i, order.orderItems[i].productName, order.orderItems[i].reservation.date, order.orderUserID)

        firestore.collection('availability').doc('date').update({  
          videoOrder: arrayUnion({
            productName: order.orderItems[i].productName,
            orderUserID: order.orderUserID,
            date: order.orderItems[i].reservation.date,
            orderId: order.orderItems[i].documentID,
            timeStamp: new Date().toLocaleString(),
          })
        }); 
      }
      else {
        // console.log(i, order.orderItems[i].productName)
      }
    }
  }
  catch (err) {
    console.log(err)
  }
  return new Promise((resolve, reject) => {
    firestore
      .collection('orders')
      .doc()
      .set(order)
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const handleGetUserOrderHistory = uid => {
  return new Promise((resolve, reject) => {
    let ref = firestore.collection('orders').orderBy('orderCreatedDate');
    ref = ref.where('orderUserID', '==', uid);

    ref
      .get()
      .then(snap => {
        const data = [
          ...snap.docs.map(doc => {
            return {
              ...doc.data(),
              documentID: doc.id
            }
          })
        ];

        resolve({ data });
      })
      .catch(err => {
        reject(err);
      });


  });
};

export const handleGetOrder = orderID => {
  return new Promise((resolve, reject) => {
    firestore
      .collection('orders')
      .doc(orderID)
      .get()
      .then(snap => {
        if (snap.exists) {
          resolve({
            ...snap.data(),
            documentID: orderID
          })
        }
      })
      .catch(err => {
        reject(err);
      })
  })
}
