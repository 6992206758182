import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './../../assets/logo.png';

import PR from './../../assets/project.jpg';
import Figure from 'react-bootstrap/Figure';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import reg_um from './../../assets/reg_um.png';
import eu from './../../assets/eu.png';
import loghiB2D from './../../assets/loghiB2D.jpg';
import fondoEu from './../../assets/fondoEU.jpg';

import './styles.scss';

const BridgeToDigital_G40 = props => {
  return (
    <Jumbotron className="mainContainer" fluid>
      <Container fluid="md">
        {/* <Row className="justify-content-md-center">
          <Col md="auto">
          <Figure>
            <Figure.Image
              src={Logo}
            />
          </Figure>
          </Col>
        </Row> */}
        <Row className="justify-content-md-center">
          <h1 style={{fontWeight:'bolder', textAlign: 'center', paddingBottom:'30px', fontSize: '45px', paddingTop:'35px'}}>BRIDGE TO DIGITAL 2020</h1>
          <br></br>
          <h2 style={{textAlign: 'center', paddingBottom:'30px'}}>POR-FESR 2014-2020 Asse III - Azione 3.7.1</h2>
          
          <Figure>
            <Figure.Image
              style={{height:'200px', paddingBottom:'30px'}}
              src={fondoEu}
            />
          </Figure>
          
          <br></br>

          <p className='testoBdir'>Interventi a sostegno delle micro e piccole imprese per l'innovazione attraverso l'utilizzo delle tecnologie dell'informazione e della comunicazione.</p>
          <h2>Il progetto Grasselli 4.0</h2>
          <p className='testoB'>Il progetto Studio Grasselli 4.0 mira ad ampliare la gamma di servizi e il bacino di clientela della
          G.F. s.r.l., attraverso l’implementazione dei seguenti interventi tecnologici:</p>

          <p className='testoB'>1. È prevista l’implementazione di una piattaforma B2C, per la vendita del servizio di
          teleassistenza e di un e-commerce per la vendita di modelli fiscali e servizi informatici.</p>
          <p className='testoB'>2. Il secondo intervento prevede la realizzazione di un sistema per il pagamento digitale sul
          dominio grasselliconsults.it. I clienti potranno vedere una raccolta di singole voci di catalogo
          che consentiranno loro di effettuare transazioni con un solo clic. L’obiettivo è di consentire
          la vendita online della teleassistenza online e dei modelli fiscali e sevizi informatici. Grazie
          alla sezione dedicata al crowdfunding lo studio potrà anche ricevere finanziamenti per la
          prosecuzione dei progetti presentati nell’apposita area, promuovendo progetti di interesse
          generale, senza fine di lucro.</p>
          <p className='testoB'>3. Il terzo intervento prevede l'implementazione di un sistema in cloud per l’archiviazione dei
          dati e di un sistema basto sull’intelligenza artificiale per analizzare e classificare i dati non
          strutturati presenti nello studio.</p>
          <p className='testoB'>
          4. Il quarto intervento prevede la realizzazione di un sistema di cyber security, per migliorare
          la sicurezza e l’integrità dei dati. Ciò consentirà di bloccare intrusioni estranee e infezioni
          da virus.
          </p>
          <p style={{paddingBottom:'40px'}} className='testoB'>
          Studio Grasselli 4.0 apporterà notevoli migliorie generando una maggior efficienza nei processi di
          lavoro, l’aumento delle prestazioni eseguite e del fatturato ed una significativa riduzione dei costi
          di gestione.
          </p>
          <br></br>
          <p className='testoBdir'>Interventions to support micro and small enterprises for innovation through the use of Information and Communication Technology.</p>
          <h2>The Grasselli 4.0 project</h2>
          <p className='testoB'>The Studio Grasselli 4.0 project aims to expand the range of services, and the customer base of
          G.F. s.r.l., through the implementation of the following technological interventions:</p>
          <p className='testoB'>1. The implementation of a B2C platform is envisaged for the sale of the remote assistance service and an e-commerce to sell tax models and IT services.</p>
          <p className='testoB'>2. The second intervention involves creating a digital payment system on the "grasselliconsults.it" domain. Customers will be able to see a collection of individual catalog
          items that will allow them to transact with a single click. The goal is to allow the online sale
          of online remote assistance, tax models, and IT services. Thanks to the section dedicated to
          crowdfunding, the studio will also receive funding for the continuation of the projects
          presented in the specific area, promoting non-profit projects of general interest.</p>
          <p className='testoB'>3. The third intervention involves implementing a cloud system for data storage and a system
          based on artificial intelligence to analyze and classify the unstructured data of the study.</p>
          <p className='testoB'>  
          4. The fourth intervention involves the creation of a cybersecurity system to improve data
          security and integrity. This will help block foreign intrusions and virus infections.
          Studio Grasselli 4.0 will bring significant improvements by generating greater efficiency in work
          processes, increased performance and turnover, and a significant reduction in management
          costs.
          </p>
          {/* <p style={{textAlign:'center'}}>
          <Figure>
            <Figure.Image
              src={reg_um}
            />
          </Figure>
          <span style={{display:'block', textAlign:'-webkit-center', paddingLeft:'25px', paddingRight:'25px'}}>REGIONE UMBRIA</span></p>
          <p style={{textAlign:'center', paddingRight:'25px'}}>
          <Figure>
            <Figure.Image
              src={eu}
            />
          </Figure>
          <span style={{display:'block', textAlign:'-webkit-center', paddingLeft:'25px', paddingRight:'25px'}}>UNIONE EUROPEA</span></p> */}

          

          {/* <p className='testoB'>Il progetto Studio Grasselli 4.0 mira ad ampliare la gamma di servizi e il bacino di clientela della
          G.F. s.r.l., attraverso l’implementazione dei seguenti interventi tecnologici:</p>

          <p className='testoB'>1. È prevista l’implementazione di una piattaforma B2C, per la vendita del servizio di
          teleassistenza e di un e-commerce per la vendita di modelli fiscali e servizi informatici.</p>
          <p className='testoB'>2. Il secondo intervento prevede la realizzazione di un sistema per il pagamento digitale sul
          dominio grasselliconsults.it. I clienti potranno vedere una raccolta di singole voci di catalogo
          che consentiranno loro di effettuare transazioni con un solo clic. L’obiettivo è di consentire
          la vendita online della teleassistenza online e dei modelli fiscali e sevizi informatici. Grazie
          alla sezione dedicata al crowdfunding lo studio potrà anche ricevere finanziamenti per la
          prosecuzione dei progetti presentati nell’apposita area, promuovendo progetti di interesse
          generale, senza fine di lucro.</p>
          <p className='testoB'>3. Il terzo intervento prevede l'implementazione di un sistema in cloud per l’archiviazione dei
          dati e di un sistema basto sull’intelligenza artificiale per analizzare e classificare i dati non
          strutturati presenti nello studio.</p>
          <p className='testoB'>
          4. Il quarto intervento prevede la realizzazione di un sistema di cyber security, per migliorare
          la sicurezza e l’integrità dei dati. Ciò consentirà di bloccare intrusioni estranee e infezioni
          da virus.
          </p>
          <p style={{paddingBottom:'40px'}} className='testoB'>
          Studio Grasselli 4.0 apporterà notevoli migliorie generando una maggior efficienza nei processi di
          lavoro, l’aumento delle prestazioni eseguite e del fatturato ed una significativa riduzione dei costi
          di gestione.
          </p>
          <h2>The Grasselli 4.0 project</h2>
          <p className='testoB'>The Studio Grasselli 4.0 project aims to expand the range of services, and the customer base of
          G.F. s.r.l., through the implementation of the following technological interventions:</p>
          <p className='testoB'>1. The implementation of a B2C platform is envisaged for the sale of the remote assistance service and an e-commerce to sell tax models and IT services.</p>
          <p className='testoB'>2. The second intervention involves creating a digital payment system on the "grasselliconsults.it" domain. Customers will be able to see a collection of individual catalog
          items that will allow them to transact with a single click. The goal is to allow the online sale
          of online remote assistance, tax models, and IT services. Thanks to the section dedicated to
          crowdfunding, the studio will also receive funding for the continuation of the projects
          presented in the specific area, promoting non-profit projects of general interest.</p>
          <p className='testoB'>3. The third intervention involves implementing a cloud system for data storage and a system
          based on artificial intelligence to analyze and classify the unstructured data of the study.</p>
          <p className='testoB'>  
          4. The fourth intervention involves the creation of a cybersecurity system to improve data
          security and integrity. This will help block foreign intrusions and virus infections.
          Studio Grasselli 4.0 will bring significant improvements by generating greater efficiency in work
          processes, increased performance and turnover, and a significant reduction in management
          costs.
          </p> */}
          <Figure>
            <Figure.Image
              src={loghiB2D}
            />
          </Figure>
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default BridgeToDigital_G40;