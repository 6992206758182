import React from 'react';
import FaqComponent from './../../components/FaqComponent';

import './styles.scss';

const Faq = props => {
  return (
    <section>
      <div>
        <FaqComponent/>
      </div>
    </section>
  );
};

export default Faq;