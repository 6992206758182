import React, { Component } from 'react';
import Faq from 'react-faq-component';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './../../assets/logo.png';

import Figure from 'react-bootstrap/Figure';
import Jumbotron from 'react-bootstrap/Jumbotron';

import './styles.scss';

const data = {
  title: "FAQ - Domande frequenti",
  rows: [
    {
        "title": "E' possibile ricevere una consulenza senza un incontro di persona con il vostro studio?",
        "content": "Certo, lo Studio Grasselli offre un servizio di Consulenza Online veloce e sicuro che può essere richiesto comodamente da casa."
    },
    {
        "title": "Lo studio riceve solo su appuntamento?",
        "content": "Normalmente si, è possibile prendere un appuntamento con in nostri consulenti per via telematica inviando una e-mail a <a href='mailto:info@grasselliconsults.it'>info@grasselliconsults.it</a> o per telefono allo <a href='tel:0758043750'>075 8043750</a>."
    },
    {
      "title": "E' necessario avere un commercialista per aprire una attività?",
      "content": "L’apertura della partita Iva e l’iscrizione in cciaa o ad altri enti può esser fatta anche autonomamente senza l’ausilio del commercialista. E' però assolutamente consigliabile rivolgersi ad un commercialista iscritto all’albo dei Dottori Commercialisti ed Esperti Contabili per evitare di incorrere in errori fatali o errate impostazioni che sono molto frequenti nel “fai da te”."
    },
    {
      "title": "Quali servizi offre lo Studio?",
      "content": "Oltre alle tradizionali attività di oltre di tenuta di contabilità, redazione di bilanci, dichiarazioni dei redditi, e consulenza fiscale, lo Studio si occupa di consulenza in materia societaria ed aziendale, contrattualistica, liquidazione di patrimoni e ristrutturazione di aziende. Lo studio è presente da 60 anni sul territorio umbro e collabora regolarmente con studi legali e notarili, studi di consulenza del lavoro e tecnici."}
  ]
}

const FaqComponent = props => {
  return (
    <Jumbotron style={{overflow: 'auto', paddingBottom: '250px'}} className="mainContainer" fluid>
      <Container fluid="md">
        <Row className="justify-content-md-center">
          <Col md="auto">
          <Figure>
            <Figure.Image
              src={Logo}
            />
          </Figure>
          </Col>
        </Row>
        {/* <Row className="justify-content-md-center">
          FAQ
        </Row> */}
        <Row className="justify-content-md-center">
          <Faq 
          styles={{
            bgColor: "white",
            titleTextColor: "#48482a",
            rowTitleColor: "#78789a",
            rowTitleTextSize: 'large',
            rowContentColor: "#48484a",
            rowContentTextSize: '16px',
            rowContentPaddingTop: '15px',
            rowContentPaddingBottom: '15px',
            rowContentPaddingLeft: '50px',
            rowContentPaddingRight: '150px',
            arrowColor: "black",
          }} 
          data={data}/>
        </Row>

      </Container>
    </Jumbotron>
  );
};

export default FaqComponent;

