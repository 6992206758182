import React from 'react';
import VoucherDigitaliImpresa2021 from './../../components/VoucherDigitaliImpresa2021';

import './styles.scss';

const VoucherDigitaliImpresa2021Page = props => {
  return (
    <section>
      <div>
        <VoucherDigitaliImpresa2021/>
      </div>
    </section>
  );
};

export default VoucherDigitaliImpresa2021Page;