import React from 'react';
import BridgeToDigital_G40 from './../../components/BridgeToDigital_G40';

import './styles.scss';

const Homepage = props => {
  return (
    <section>
      <div>
        <BridgeToDigital_G40/>
      </div>
    </section>
  );
};

export default Homepage;