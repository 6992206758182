import React from 'react';
import './styles.scss';

const Footer = props => {
  return (
    <footer className="footer">
      <div className="wrap">
        <p>© 2021 Copyright: G.F. s.r.l</p>
        <p><i className="fa fa-home mr-3" /> <a className = "contacts" target="_blank" href="https://goo.gl/maps/rzP1K9A2CWfDiUGV6">Via Los Angeles, 65, 06081 Santa Maria degli Angeli (PG)</a></p>
        <p><i className="fa fa-envelope mr-3" /> <a className = "contacts" target="_blank" href="mailto:info@grasselliconsults.it">info@grasselliconsults.it</a></p>
        <p><i className="fa fa-phone mr-3" /> <a className = "contacts" target="_blank" href="tel:0758043750">075 8043750</a></p>
      </div>
    </footer>
  );
}

export default Footer;