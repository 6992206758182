import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './../../assets/logo.png';

import PR from './../../assets/project.jpg';
import Figure from 'react-bootstrap/Figure';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import reg_um from './../../assets/reg_um.png';
import eu from './../../assets/eu.png';
import camcomUm from './../../assets/camcomUm.jpg';
import pdiLogo from './../../assets/pdiLogo.png';



import './styles.scss';

const VoucherDigitaliImpresa2021 = props => {
  return (
    <Jumbotron className="mainContainer" fluid>
      <Container fluid="md">

        <Row className="justify-content-md-center">
          <h1 style={{fontWeight:'bolder', textAlign: 'center', paddingBottom:'30px', fontSize: '45px', paddingTop:'35px'}}>Bando Voucher Digitali Impresa 4.0</h1>
          <br></br>
          {/* <h2 style={{textAlign: 'center', paddingBottom:'30px'}}>POR-FESR 2014-2020 Asse III - Azione 3.7.1</h2> */}
          
          <Figure>
            <Figure.Image
              style={{height:'200px', paddingBottom:'30px'}}
              src={pdiLogo}
            />
          </Figure>
          
          <br></br>

          <p className='testoBdir'>Interventi a sostegno delle micro e piccole imprese per l'innovazione attraverso l'utilizzo delle tecnologie dell'informazione e della comunicazione.</p>

          <p className='testoB'>Si ringrazia la Camera di commercio dell’Umbria per aver supportato la nostra impresa nell’ambito delle
          attività previste dal Piano Transizione 4.01, a seguito del decreto del Ministro dello Sviluppo economico del 12 marzo 2020 che ha approvato il progetto “Punto Impresa Digitale” (PID).</p>
          
          <p className='testoB'>PDI intende promuovere la diffusione della cultura e della pratica digitale nelle Micro, Piccole e Medie Imprese.</p>
          
          <div style={{width: '-webkit-fill-available'}} className='text-center'>
          <img
          style={{width:'200px', height:'auto!important'}}
          src={camcomUm}
          />

          </div>
         
          

          
        </Row>
      </Container>
    </Jumbotron>
  );
};

export default VoucherDigitaliImpresa2021;