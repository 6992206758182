import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from './../../assets/logo.png';

import PR from './../../assets/project.jpg';
import Figure from 'react-bootstrap/Figure';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import ProgressBar from 'react-bootstrap/ProgressBar'
import DateCountdown from 'react-date-countdown-timer';

import { firestore } from '../../firebase/utils';

import './styles.scss';
import { DoneAll } from '@material-ui/icons';

const CrowdfundingComponent = props => {

  const [soldi1, setSoldi1] = useState(0);
  const [soldi2, setSoldi2] = useState(0);
  const [soldi3, setSoldi3] = useState(0);

  const [soldiRaccolti, setSoldiRaccolti] = useState([]);

  // const data = {
  //   soldi1: 1500,
  //   soldi2: 1200,
  //   soldi3: 14000
  // };
  
  // // Add a new document in collection "cities" with ID 'LA'
  // const res = firestore.collection('crowdfunding').doc('donazioni').set(data);

  useEffect(() => {
    function fetchData() {
      firestore.collection('crowdfunding')
        .get()
        .then(result => {
          const data = result.docs.map(item => {
            return {
              ...item.data(),
            };
          });
          setSoldiRaccolti(data[0]);
        });
    }
    fetchData();
    // console.log(soldiRaccolti,'inner')
  }, []);
  // console.log(soldiRaccolti,'outer')

  const tot1 = 15500;
  const tot2 = 17500;
  const tot3 = 112000;

  useEffect(() => {
    function setData() {
      setSoldi1(soldiRaccolti[Object.keys(soldiRaccolti)[0]])
      setSoldi2(soldiRaccolti[Object.keys(soldiRaccolti)[1]])
      setSoldi3(soldiRaccolti[Object.keys(soldiRaccolti)[2]])
    }
    setData();
    // console.log(soldiRaccolti,'inner')
  }, [soldiRaccolti]);
  // console.log(soldiRaccolti,'outer')
  

  const nowProgramma1 = Math.round((soldi1/tot1*100));
  const nowProgramma2 = Math.round((soldi2/tot2*100));
  const nowProgramma3 = Math.round((soldi3/tot3*100));
  
  return (
    <Jumbotron className="mainContainer" style={{overflow: 'auto', paddingBottom: '250px'}} fluid>
      <Container fluid="md">
        <Row className="justify-content-md-center" >
          <Col md="auto">
          <Figure>
            <Figure.Image
              src={Logo}
            />
          </Figure>
          </Col>
        </Row>
        <Row className="justify-content-md-center" >
          Progetti di interesse generale, senza fine di lucro.
        </Row>
        <Row className="justify-content-md-center">
          <Row>
            <Col md="auto">
              <CardDeck>

                <Card>
                  <Card.Img variant="top" src={PR} />
                  <Card.Body>
                    <Card.Title>Progetto 1</Card.Title>
                    <Card.Text>
                      Software di gestione Amministrativa 2.0.
                    </Card.Text>
                    <Card.Text className='euroRaccolti'>
                      <span className='soldiRaccolti'>{soldi1}</span> € raccolti su <span className='soldiTot'>{tot1} €</span>
                      <ProgressBar now={nowProgramma1} label={`${nowProgramma1}%`} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                  <DateCountdown
                      numberOfFigures={3} 
                      locales={["anno", "mese", "giorno", "ora", "min", "sec"]}
                      locales_plural={[
                        "anni",
                        "mesi",
                        "giorni",
                        "ore",
                        "min",
                        "sec",
                      ]}
                      dateTo='June 15, 2024 14:00:00 GMT+03:00'/>
                  </Card.Footer>
                </Card>

                <Card>
                  <Card.Img variant="top" src={PR} />
                  <Card.Body>
                    <Card.Title>Progetto 2</Card.Title>
                    <Card.Text>
                    Software di gestione Fiscale e Tributaria 2.0.
                    </Card.Text>
                    <Card.Text className='euroRaccolti'>
                      <span className='soldiRaccolti'>{soldi2}</span> € raccolti su <span className='soldiTot'>{tot2} €</span>
                      <ProgressBar now={nowProgramma2} label={`${nowProgramma2}%`} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    <DateCountdown
                      numberOfFigures={3} 
                      locales={["anno", "mese", "giorno", "ora", "min", "sec"]}
                      locales_plural={[
                        "anni",
                        "mesi",
                        "giorni",
                        "ore",
                        "min",
                        "sec",
                      ]}
                      dateTo='July 30, 2024 14:00:00 GMT+03:00'/>
                  </Card.Footer>
                </Card>

                <Card className="top">
                  <Card.Img variant="top" src={PR} />
                  <Card.Body>
                    <Card.Title>Progetto 3</Card.Title>
                    <Card.Text>
                    Software di gestione di documenti legali 2.0.
                    </Card.Text>
                    <Card.Text className='euroRaccolti'>
                      <span className='soldiRaccolti'>{soldi3}</span> € raccolti su <span className='soldiTot'>{tot3} €</span>
                      <ProgressBar now={nowProgramma3} label={`${nowProgramma3}%`} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    <DateCountdown
                      numberOfFigures={3} 
                      locales={["anno", "mese", "giorno", "ora", "min", "sec"]}
                      locales_plural={[
                        "anni",
                        "mesi",
                        "giorni",
                        "ore",
                        "min",
                        "sec",
                      ]}
                      dateTo='December 15, 2026 14:00:00 GMT+03:00'/>
                  </Card.Footer>
                </Card>

              </CardDeck>
            </Col>
          </Row>
        </Row>
        
        <Row className="justify-content-md-center">
        <form action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="hosted_button_id" value="LNJ83XMCGLQ8U" />
        <input type="image" src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
        <img alt="" border="0" src="https://www.paypal.com/it_IT/i/scr/pixel.gif" width="1" height="1" />
        </form>
        </Row>
        
      </Container>
    </Jumbotron>
  );
};

export default CrowdfundingComponent;