import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signOutUserStart } from './../redux/User/user.actions';

import Header from './../components/Header';
import VerticalNav from './../components/VerticalNav';
import Availability from './../components/Availability';
import Footer from './../components/Footer';

const AdminLayout = props => {
  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(signOutUserStart());
  };
  const [availability, setAvailability] = React.useState(false);
  const [home, setHome] = React.useState(true);

  function isAvailability () {
    setHome(false);
    setAvailability(true);
  }

  function isHome () {
    setAvailability(false);
    setHome(true)
  }

  return (
    <div className="adminLayout">
      <Header {...props} />
      <div className="controlPanel">
        <div className="sidebar">
          <VerticalNav>
            <ul>
              <li>
                <a onClick={isHome}>
                  Home
                </a>
              </li>
              <li>
                <a onClick={isAvailability}>
                  Disponibilità
                </a>
              </li>
              <li>
                <span className="signOut" onClick={() => signOut()}>
                  Esci
                </span>
              </li>
            </ul>
          </VerticalNav>
        </div>
        
        <div>
          {(()=> {
            if ( home == true ) {
                return (
                  <div className="content">
                    {props.children}
                  </div>
                );
            } 
            if ( availability == true ) {
                return (
                  <div className="mainBoxTable">
                    <Availability />
                  </div>
                );
            }
          })()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminLayout;
