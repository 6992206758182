import React from 'react';
import Directory from './../../components/Directory';
import './styles.scss';

const Service = props => {
  return (
    <div className='service'>
      <Directory />
    </div>
  );
};

export default Service;