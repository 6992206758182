import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct } from './../../redux/Products/products.actions';
import { addProduct } from './../../redux/Cart/cart.actions';
import Button from './../forms/Button';

import 'antd/dist/antd.css';
import moment from 'moment';
import { DatePicker, Space } from 'antd';
import { useVenti } from 'venti';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { firestore } from '../../firebase/utils';

import './styles.scss'

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
}
  
// Chiama firestore per elenco date non disponibili
// const disabledDates = [
//     "2021-01-21 09",
//     "2021-01-21 12",
//     "2021-01-25 10",
// ];

function disabledDateTime() {
    return {
        disabledHours: () => [0,1,2,3,4,5,6,7,8,13,14,19,20,21,22,23,24],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
    };
}

const mapState = state => ({
    product: state.productsData.product
});
  
const DataTimer = ({}) => {

    const state = useVenti() // VENTI this is needed so your component updates when state changes
    const [date, setDate] = React.useState(null);
    const [newProduct, setNewProduct] = React.useState(null);
    const [queryDates, setQueryDates] = React.useState([]);
    const [queryDone, setQueryDone] = React.useState(false);

    function disabledDate(current) {
        if (queryDone==false) {
            setQueryDone(true)
            const usersRef = firestore.collection('availability').doc('date');
            usersRef.get()
            .then((docSnapshot) => {
                if (docSnapshot.exists) {
                usersRef.onSnapshot((doc) => { 
                setQueryDates(docSnapshot.data().videoOrder);
                });
                }
                else {
                // console.log('errore');
                }
            });
        }

        const disabledDate = []
        
        // display all values
        for (var i = 0; i < queryDates.length; i++) {
            // append multiple values to the array
            var str = queryDates[i].date
            str = str.replace(',','');
            str = str.replace('/','-');
            str = str.replace('/','-');
            str = str.slice(0,str.length-6);

            disabledDate.push(str);
        }
        // {queryDates.map((item, i) => (
        //     item.date
        // ))}
        // const disabledDate = videoOrder.date
        // Can not select days before today and today; disabilita tutti i sabato e domenica.
        // console.log(disabledDate)
        return (
            current && current < moment().endOf('day') ||
            moment(current).day() === 0 || 
            moment(current).day() === 6 ||
            disabledDate.find(date => date === moment(current).format("D-M-YYYY HH"))
            
        );
    }

    function onOk(value) {
        // VENTI set dataAppuntamento
        setDate(value._d)
        state.set('DataPicker', {
        dataAppuntamento: value._d,
        })
        setDate(value._d)

        let productAdd
        productAdd = {
			...product,
			[`reservation`]: {
				date: value._d.toLocaleString('it-IT'), 
			}
		}
        setNewProduct(productAdd)
        // console.log(newProduct)
    }

    const dispatch = useDispatch();
    const history = useHistory();
    const { productID } = useParams();
    const { product } = useSelector(mapState);

    const {
        productThumbnail,
        productName,
        productPrice,
        productDesc,
    } = product;

    useEffect(() => {
        dispatch(
            fetchProductStart(productID)
        )
        return () => {
            dispatch(
                setProduct({})
            )
        }
    }, []);

    const handleAddToCart = (product) => {
        if (!product) return;
        dispatch(
            addProduct(newProduct)
        );
        history.push('/cart');
    }

    const configAddToCartBtn = {
        type: 'button'
    }

    return (
        <div className="productCard">
        <div className="hero">
            <img src={productThumbnail} />
        </div>
        <h1>
        {productName}
        </h1>
            
        <span>
        €{productPrice}
        </span>
        
        <div className = "mainCalendar">
            <div className="titleCal">
                Seleziona il giorno e l'ora.
            </div>
        <Space direction="vertical" size={12}>
            <DatePicker
            // headerCells={['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab']}
            showNow={false}
            format="YYYY-MM-DD HH:mm"
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            showTime={{ defaultValue: moment('09:00', 'HH:mm') }}
            onOk = {onOk}
            />
        </Space>
        <div className="containAvailability">
            {(()=> {
                if (date != null) {
                var options = {'weekday': 'long', 'month': 'long', 'day': '2-digit'};
                var optionsTime = {'weekday': 'long', 'month': 'long', 'day': '2-digit'};
                if (date != queryDates) {
                    return (
                        <div>
                            <Container>
                                <Row>
                                <Col>
                                    <p className="pDatasel"><span className="pOrderElement">data selezionata:</span> {date.toLocaleString('it-IT', options)} ore {date.toLocaleTimeString()}</p>
                                </Col>
                                </Row>
                            </Container>
                            <div className="productDetails" style={{textAlign: "-webkit-center"}}>
                                <ul>
                                    <li>
                                        <div className="addToCart">
                                        <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product)}>
                                            Aggiungi al carrello
                                        </Button>
                                        </div>
                                    </li>
                                    <li>
                                        <span
                                            className="desc"
                                            dangerouslySetInnerHTML={{ __html: productDesc }} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    );
                } 
                else {
                    return (
                    <Container>
                        <Row>
                        <Col>
                            <p className="pDatasel"><span className="pOrderElement">Attenzione la data {date.toLocaleString('it-IT', options)} non è disponibile! Per favore selezionare un'altra data.</span> </p>
                        </Col>
                        </Row>
                    </Container>
                    );
                }
                }
            })()}
        </div>
        </div>
        </div>
    );
}

export default DataTimer;


   

 